import React from "react";
import { NavLink, useLocation, Link} from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { connectWallet, getCurrentWalletConnected } from "./utils/interact.js";
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import LanguageContext from "./language-context";

const style1 = {
      height: "20px",
      width: "20px"
    };







const style2 ={
  height: "20px",
  width: "20px",
  display: "inline-flex",
  alignItems: "center",
  fontWeight: "500",
  textDecorationSkipInk: "auto",
}

const nav = [
  { name: 'THE RIDE', href: '/info#ride' },
  { name: 'THE DESIGN', href: '/info#testdrive' },
  { name: 'ROADMAP', href: '/info#roadmap' },
]

/*const nav = [
  { name: 'ALLOWLIST SALE', href: '/allowlist' },
  { name: 'PUBLIC SALE', href: '/minting' },
]*/


const Navigation = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const[amount,setAmount] = useState("1");
  const [leftAmount,setLeftAmount] = useState("9,999");
  const [claimed, setClaimed] = useState("");
  //const [name, setName] = useState("");
  //const [description, setDescription] = useState("");
  //const [url, setURL] = useState("");
  
  const [publicPrice,setPublicPrice] = useState("0.08Ξ");
  const [allowlistPrice,setAllowlistPrice] = useState("0.1Ξ");
  const [saleState,setSaleState] = useState("CLOSE");
  const [allowAmount,setAllowAmount] = useState("0");



  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [network, setNetwork] = useState();
  const [reset, setReset] = useState(false);

  const { language, setLanguage } = useContext(LanguageContext);

  let location = useLocation();

  const [showMintlink, setShowMintlink] = useState(<Link to="/minting" className="ml-2 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-purple-500 to-cyan-500 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-semibold text-white hover:from-purple-700 hover:to-indigo-700">
                         Allowlist Checker</Link>)

  const [connectButtonText, setConnectButtonText] = useState("Connect Wallet")

  

  useEffect(()=> {
    
    
    


  
    const fetchData = async() =>{
      const {address, status, leftAmount, publicPrice,allowlistPrice, saleState, allowlistState, allowAmount,claimed} = await getCurrentWalletConnected();
      
      
      setWallet(address)
      setStatus(status)
    }


    fetchData();

    if(location){
      if(location.pathname.includes('allowlist') || location.pathname.includes('minting') ){
        setShowMintlink()
      }
      else{
        setShowMintlink(<Link to="/minting" className="ml-2 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-purple-500 to-cyan-500 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-semibold text-white hover:from-purple-700 hover:to-indigo-700">
                         Public Mint</Link>)
      }

      if(location.pathname.includes('allowlist')){
        setConnectButtonText('Connect to Check Allowist')
      }
      else{
        setConnectButtonText('Connect to Wallet')
      }
      
    }

   
      
    //addWalletListener();
  }, [language,location]);

  const connectWalletPressed = async () => { //TODO: implement


     const{address,status} = await connectWallet();
     setWallet(address)
     setStatus(status); 
     //setReset(true);
    
     
     //console.log(language)
     setLanguage(address)
     //console.log(language)
     
/*  
    if(!window.ethereum){
      window.addEventListener('ethereum#initialized', handleEthereum, {
        once: true,
      });

      // If the event is not dispatched by the end of the timeout,
      // the user probably doesn't have MetaMask installed.
      setTimeout(handleEthereum, 3000); // 3 seconds

      if(!window.ethereum){

        alert("You must install Metamask, a virtual Ethereum wallet, in your browser. here")
           
      }
      
    }
    
  */
  };
 
  
  



  return (


     <header className="sticky top-0 z-30 bg-white">

     
     
       <div className="relative px-4 py-6 sm:px-6">
        <Popover>
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <nav className="relative flex items-center justify-between sm:h-10 lg:justify-center" aria-label="Global">
              <div className="flex items-center flex-1 lg:absolute lg:inset-y-0 lg:left-0">
                <div className="flex items-center justify-between w-full lg:w-auto">
                  <Link to="/">
                  <span className="sr-only">Logo</span>
                   <img className="h-5 w-auto sm:h-5" src="img/svg/cyberride.svg" alt=""/>
                  </Link>
                  <div className="-mr-2 flex items-center lg:hidden">
                    <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-cyan-500 font-bold hover:text-cyan-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"  as="button" onClick={() => {
  // Do what you need to do here


}}>
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="hidden lg:text-sm xl:text-base lg:flex lg:space-x-4 xl:space-x-10">
                {nav.map((item) => (
                  <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-cyan-500">
                    {item.name}
                  </a>
                ))}
              </div>
               

              <div className="hidden lg:absolute lg:flex lg:items-center lg:justify-end lg:inset-y-0 lg:right-0">
               

                        
                         {showMintlink}
                                   

                  <button className="ml-2 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-purple-500 to-cyan-500 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-semibold text-white hover:from-purple-700 hover:to-indigo-700" id="walletButton" onClick={connectWalletPressed}>
              {walletAddress.length > 0 ? (
                "Connected: " +
                String(walletAddress).substring(0, 6) +
                "..." +
                String(walletAddress).substring(38)
              ) : (
                <span>{connectButtonText}</span>
              )}
           </button>



               
              </div>
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
             
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
            >
              <div className="rounded-lg shadow-md bg-white overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                  <Link to="/" className="focus:outline-none">
                    <img
                      className="h-5 w-auto focus:outline-none"
                      src="img/svg/cyberride.svg"
                      alt="home" 
                    />
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-4 pt-3 pb-3">

                  {nav.map((item) => (
                    <Popover.Button  key={item.name}>
                    <a
                     
                      href={item.href}
                      className="block px-4 py-4 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                    </Popover.Button>
                  ))}

                  
                   <Popover.Button key="mint">

                   
                         {showMintlink}

                  
                  </Popover.Button>
              


                </div>

               
                <Link
                  to="#"
                  className="block w-full px-5 py-3 text-center font-medium text-indigo-600 hover:bg-gray-100"
                >
 <button className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-purple-500 to-cyan-500 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-semibold text-white hover:from-purple-700 hover:to-indigo-700" id="walletButton" onClick={connectWalletPressed}>
              {walletAddress.length > 0 ? (
                "Connected: " +
                String(walletAddress).substring(0, 6) +
                "..." +
                String(walletAddress).substring(38)
              ) : (
                <span>{connectButtonText}</span>
              )}
           </button>
                
                </Link>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        </div>

    </header>

    
       

  );
};

export default Navigation;



