import Web3Modal from "web3modal";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";
import { ethers } from 'ethers';
import { useEffect, useState } from "react";
import allowlistData from './test.json';

require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const contractABI = require('../../contract-abi.json')
const contractAddress = "0x8430066B2c841f6357F1B6F090a44FCEb8c51B00";

var walletAddress = ""
var provider = ""
var library = ""
var network = ""



const { MerkleTree } = require('merkletreejs')
const keccak256 = require('keccak256')
var allowListTree = new MerkleTree([], keccak256, {sortPairs: true});


export const providerOptions = {
 coinbasewallet: {
   package: CoinbaseWalletSDK, 
   options: {
     appName: "CyberRide",
     rpc: {
          1: "https://eth-mainnet.g.alchemy.com/v2/W9T6jLXTXltdNJ-MQ9OviELg0f9fZR5I",
          5: "https://eth-goerli.g.alchemy.com/v2/ULF00SN43xUSab0gSMYMHbTe7A6QpPe2"
         
          
    },
   }
 },
 walletconnect: {
   package: WalletConnect, 
   options: {
     rpc: {
          1: "https://eth-mainnet.g.alchemy.com/v2/W9T6jLXTXltdNJ-MQ9OviELg0f9fZR5I",
          5: "https://eth-goerli.g.alchemy.com/v2/ULF00SN43xUSab0gSMYMHbTe7A6QpPe2"
         
          
        },


     
    
   }
 }
};


const web3Modal = new Web3Modal({
  cacheProvider: true, // very important
  network: "mainnet",
  providerOptions, // required
});





export const connectWallet = async () => {
     try {     
      provider = await web3Modal.connect();
      //addListeners(provider);

      library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      

      network = await library.getNetwork();
      
      if(accounts.length>0){
        walletAddress=accounts[0]
      }
      


    
      return {
          address: walletAddress,
          status: "Wallet Connected Successfully",
      };
      
      //provider.disconnect()

      /*if(accounts.length>0){
        walletAddress = accounts[0];
        var a = new Date();
       
        localStorage.setItem("walletAddress", JSON.stringify(walletAddress));
        localStorage.setItem("setDate", a.getTime());
        //const b = localStorage.getItem("setDate");
        window.address = walletAddress;
        return {
          address: accounts[0],
          status: "Wallet Connected Successfully",
        };
      }
      return {
          address: "",
          status: "Wallet Not Connected",
      };*/
      
     
    } catch (error) {
      console.log(error.message)
      return {
          address: "",
          status: "Wallet Not Connected",
        };
    }
}



const padBuffer = (addr) => {
  //return Buffer.from(addr.substr(2).padStart(32 * 2, 0), "hex");
  return addr;
};



function generateTree(){
    var arr =[]
    for(var i in allowlistData){
        arr.push(allowlistData [i]['Address']);
    }
    const leafNodes = arr.map(addr => keccak256(padBuffer(addr)));
    const merkleTree = new MerkleTree(leafNodes, keccak256, {sortPairs: true});



    
    return merkleTree;
}


// return true if address is in tree
function checkIfInTree(targetAddress) {
    

    allowListTree = generateTree()
    const rootHash = allowListTree.getRoot().toString('hex');

   
    //console.log('root is')
    //console.log(rootHash)
    const hashAddress =keccak256(padBuffer(targetAddress));
   
    const hexProof = allowListTree.getHexProof(hashAddress)
    const result = allowListTree.verify(hexProof, hashAddress, rootHash);


     // remove this after test
    // use this test for all address should be able to prass verfied tree check
    /*var arr =[]
    for(var i in allowlistData){
        arr.push(allowlistData [i]['Address']);
    }
    for (var i in arr){
        var paddedArr = keccak256(padBuffer(arr[i]));
        var newProof = allowListTree.getHexProof(paddedArr)
        //console.log('checking '+i)
        if(!allowListTree.verify(newProof, paddedArr, rootHash)){
          console.log(paddedArr+" did not pass")
        }

    }
    console.log('checked done')
    */

    return result;
}


// return true if address is in tree
function getTreeProof(targetAddress) {

    const rootHash = allowListTree.getRoot().toString('hex');
   
    const hashAddress =keccak256(padBuffer(targetAddress));
   
    const hexProof = allowListTree.getHexProof(hashAddress)

    return hexProof
}






export const mintNFT = async(amount) => {
  //error handling
  const intAmount = parseInt(amount,10);
  let currentLeft = "";
  let currentPrice ="";
  let currentSaleState = "";
  let txnHash ="";

  if(web3Modal && web3Modal.cachedProvider){
      await connectWallet()

  }
  else{
    return {
            success: false,
            status: "Please make sure to connect to your wallet before minting.",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
        }
  } 
  


  const netId =await web3.eth.net.getId();
  const strNetId = netId+"";
  if(network.chainId!=strNetId){
    return {
            success: false,
            status: "Wrong Ethereum network detected. To successfully mint, please switch to Ethereum Mainnet in your wallet.",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
    }
  }

  if (amount.trim() === "" ||  Number.isInteger(intAmount)===false || intAmount<1) {
        return {
            success: false,
            status: "Please make sure to select a valid integer amount before minting.",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
        }
  }

  if(intAmount >5){
    return {
            success: false,
            status: "Max Ride per purchase is 5. Please set a valid value before minting.",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
    }
  }


  

  await window.contract.methods.saleIsActive.call().call().then(value  => {
    if(value===true){
      currentSaleState = "OPEN";
    }else{
      currentSaleState = "CLOSED";
    }
  });
  
  if(currentSaleState === "CLOSED"){
    return {
            success: false,
            status: "Sale is not open. Please wait till sale is open to mint.",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
    }
  }
  let nextTokenID = 0;
  await window.contract.methods.totalSupply.call().call().then(value  => {
    value=parseFloat(value)
    nextTokenID = value+"";
    
    currentLeft = 6666-value;
  });

  if(currentLeft<intAmount){
     return {
            success: false,
            status: "Mint amount exceeds total available supply. Please select a valid mint amount",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
    }
  }
  let mintCost = 0.025;

  await window.contract.methods.publicSalePrice.call().call().then(value  => {
    mintCost = parseFloat(web3.utils.fromWei(value,'ether'));
    currentPrice = String(mintCost)+"Ξ";
  });
  
  let errorCame = false;
  let theError = "";
  let gasEst = "";

  let mintAmount=amount;
  
  let theAmount = (mintAmount * mintCost).toString();
  const amountToSend = web3.utils.toWei(theAmount, "ether");
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const theABIData = window.contract.methods
    .mintRide(mintAmount)
    .encodeABI();
  
  

  let isSufficient = true 
  // Many web3.js methods return promises.
 
    await web3.eth.getBalance(walletAddress).then(QUANTITY  => {
      /* … */
      let a = parseFloat(amountToSend);
      let q= parseFloat(QUANTITY);
     
      if(q<=a){
      
        isSufficient = false
      }
    });
    
   
  
  

  if(!isSufficient){
    return {
          success: false,
          status: "Insufficient fund in your wallet. Please select proper amount or refill your wallet",
          leftAmount: currentLeft,
          price:currentPrice,
          saleState: currentSaleState,
          web3: web3,
          txnHash: txnHash,
      }
  }

  
  

  //set up your Ethereum transaction
  const params = [{
    to: contractAddress, // Required except during contract publications.
    from: walletAddress, // must match user's active address.
    value: web3.utils.toHex(amountToSend),
    //gasPrice: web3.utils.toWei((gasEst.toString(),"ether")),
    //gasLimit: gasEst,
    //nonce: web3.utils.toHex(nonce),
    data: theABIData,
  }];

   
   

  //sign the transaction via Metamask
  try {
      var transStatus =false;
      var errorMessage = "";
    




      

            await provider.request({
                  method: 'eth_sendTransaction',
                  params,
                })
            .then((result) => {
              // The result varies by RPC method.
              // For example, this method will return a transaction hash hexadecimal string on success.

             
              txnHash = result;
              transStatus = true;
              //console.log('txn hash')
              //console.log(txnHash)
             
            })
            .catch((error) => {
              // If the request fails, the Promise will reject with an error.
          
              transStatus = false;
              errorMessage = error.message
             
            });
            

            if(transStatus){
              return {
                    success: true,
                    status: (
                    <span>
                      <p>
                        {" "}
                        ✅{" "}
                        <a target="_blank" rel="noopener noreferrer" href={'https://opensea.io/account'}>
                         Mint Successful. Click here to view your CyberRide on OpenSea.
                        </a>
                      </p>
                    </span>
                  ),

                    
                    leftAmount: currentLeft,
                    price:currentPrice,
                    saleState: currentSaleState,
                    web3: web3,
                    txnHash: txnHash,
                  }
            }
            else{
              return {
                    success: false,
                    status:errorMessage,
                    leftAmount: currentLeft,
                    price:currentPrice,
                    saleState: currentSaleState,
                    web3: web3,
                    txnHash: txnHash,
                  }
            }



    
 } catch (error) {
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
        leftAmount: currentLeft,
        price:currentPrice,
        saleState: currentSaleState,
        web3: web3,
        txnHash: txnHash,
    }

 }
}



export const mintAllowListNFT = async(amount) => {
  
  //error handling
  const intAmount = parseInt(amount,10);
  let currentLeft = "";
  let currentPrice ="";
  let currentSaleState = "";
  let txnHash ="";
  let alreadyClaimed = false;
  

  if(web3Modal && web3Modal.cachedProvider){
      await connectWallet()
  }
  else{
    return {
            success: false,
            status: "Please make sure to connect to your wallet before minting.",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
            claimed: alreadyClaimed,
        }
  } 


  
  var ifAllowed =  checkIfInTree(walletAddress);
  if(!ifAllowed){
    return {
            success: false,
            status: "😥 You address is not in the allowlist. Please wait for public sale to mint.",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
            claimed: alreadyClaimed,
    }
  }
  

  let currentAllowAmount = -1;
  var claimed = await window.contract.methods.alreadyClaimed(walletAddress).call({from:walletAddress}, function(error, result){
                if(result===true){
                  currentAllowAmount =0
                  alreadyClaimed= true;
                } 
  });

   await window.contract.methods.isAllowListActive.call().call().then(value  => {
    if(value===true){
      currentSaleState = "OPEN";
    }else{
      currentSaleState = "CLOSED";
    }
  });
  
  if(currentSaleState === "CLOSED"){
    return {
            success: false,
            status: "Allowlist sale is not open. Please wait untill allowlist is open to mint.",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
            claimed: alreadyClaimed,
    }
  }


  const netId =await web3.eth.net.getId();
  const strNetId = netId+"";
   
  if(network.chainId!=strNetId){
    return {
            success: false,
            status: "Wrong Ethereum network detected. To successfully mint, please switch to Ethereum Mainnet in your wallet.",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
            claimed: alreadyClaimed,
    }
       
  }
  if (amount.trim() === "" ||  Number.isInteger(intAmount)===false || intAmount<1) {

   
        return {
            success: false,
            status: "Please make sure to select a valid integer amount before minting.",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
            claimed: alreadyClaimed,
        }
  }

  if(intAmount >1){
    return {
            success: false,
            status: "Max Ride per Allowlist is 1. Please set a valid value before minting.",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
            claimed: alreadyClaimed,
    }
  }

  
 
  
  
  


  if(currentAllowAmount===0){
    return {
            success: false,
            status: "You have already minted your quota. Please wait for public sale to mint.",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
            claimed: alreadyClaimed,
    }
  }

  

  let nextTokenID = 0;
  await window.contract.methods.totalSupply.call().call().then(value  => {
    value=parseFloat(value)
    nextTokenID = value+"";
    currentLeft = 6666-value;
  });

  if(currentLeft<intAmount){
     return {
            success: false,
            status: "Mint amount exceeds total available supply. Please select a valid mint amount",
            leftAmount: currentLeft,
            price:currentPrice,
            saleState: currentSaleState,
            web3: web3,
            txnHash: txnHash,
            claimed: alreadyClaimed,
    }
  }
  let mintCost = 0.02;
  await window.contract.methods.allowListPrice.call().call().then(value  => {
    mintCost = parseFloat(web3.utils.fromWei(value,'ether'));
    currentPrice = String(mintCost)+"Ξ";
  });



  let mintAmount=amount;
  let theAmount = (mintAmount * mintCost).toString();
  const amountToSend = web3.utils.toWei(theAmount, "ether");
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  var allowlistProof = getTreeProof(walletAddress)

 
  const theABIData = window.contract.methods
    .mintAllowList(allowlistProof)
    .encodeABI();

  
  let errorCame = false;
  let theError = "";
  let gasEst = "";


  let isSufficient = true 
  // Many web3.js methods return promises.
 
    await web3.eth.getBalance(walletAddress).then(QUANTITY  => {
      /* … */
      let a = parseFloat(amountToSend);
      let q= parseFloat(QUANTITY);
     
      if(q<=a){
        //console.log('insuf')
        isSufficient = false
      }
    });
    
   
  
  

  if(!isSufficient){
    return {
          success: false,
          status: "Insufficient fund in your wallet. Please select proper amount or refill your wallet",
          leftAmount: currentLeft,
          price:currentPrice,
          saleState: currentSaleState,
          web3: web3,
          txnHash: txnHash,
          claimed: alreadyClaimed,
      }
  }

  
  

  //set up your Ethereum transaction
  const params = [{
    to: contractAddress, // Required except during contract publications.
    from: walletAddress, // must match user's active address.
    value: web3.utils.toHex(amountToSend),

    //gasPrice: web3.utils.toWei((gasEst.toString(),"ether")),
    //gasLimit: gasEst,
    //nonce: web3.utils.toHex(nonce),
    data: theABIData,
  }];

   


  //sign the transaction via Metamask
  try {
        
       
       
      
         
        var transStatus =false;
        var errorMessage = "";
        

        //txnHash =  await window.contract.methods.mintAllowList().sendTransaction(allowlistProof,transactionParameters);
        
        //txnHash = await provider.send('eth_sendTransaction',transactionParameters);

        await provider.request({
                  method: 'eth_sendTransaction',
                  params,
                })
            .then((result) => {
              // The result varies by RPC method.
              // For example, this method will return a transaction hash hexadecimal string on success.

             
              txnHash = result;
              transStatus = true;
              
             
            })
            .catch((error) => {
              // If the request fails, the Promise will reject with an error.
          
              transStatus = false;
              errorMessage = error.message
             
            });
            

            if(transStatus){
              return {
                  success: transStatus,
                  status: (
                  <span>
                    <p>
                      {" "}
                      ✅{" "}
                      <a target="_blank" rel="noopener noreferrer" href={'https://opensea.io/account'}>
                       Mint Successful. Click here to view your CyberRide on OpenSea. 
                      </a>
                    </p>
                  </span>
                ),           
                  leftAmount: currentLeft,
                  price:currentPrice,
                  saleState: currentSaleState,
                  web3: web3,
                  txnHash: txnHash,
                  claimed: true,
                }
            }
            else{
              return {
                  success: false,
                  status: "😥 Something went wrong: " + errorMessage,
                  leftAmount: currentLeft,
                  price:currentPrice,
                  saleState: currentSaleState,
                  web3: web3,
                  txnHash: "",
                  claimed: false,
              }


            }
            
        
        
 } catch (error) {
    
    return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
        leftAmount: currentLeft,
        price:currentPrice,
        saleState: currentSaleState,
        web3: web3,
        txnHash: "",
        claimed: alreadyClaimed,
    }

 }
}



export const getCurrentWalletConnected = async () => {
  
  let currentLeft ="";
  let currentPublicPrice = "";
  let currentAllowlistPrice = "";
  let currentSaleState = "";
  let currentAllowlistSaleState ="";
  let currentAllowAmount ="";
  let alreadyClaimed =false;

  window.contract = await new web3.eth.Contract(contractABI, contractAddress);



  await window.contract.methods.saleIsActive.call().call().then(value  => {
    if(value===true){
      currentSaleState = "OPEN";
    }else{
      currentSaleState = "CLOSED";
    }
  });
  
  await window.contract.methods.isAllowListActive.call().call().then(value  => {
    if(value===true){
      currentAllowlistSaleState = "OPEN";
    }else{
      currentAllowlistSaleState = "CLOSED";
    }
  });
  


  await window.contract.methods.totalSupply.call().call().then(value  => {
    value=parseFloat(value)
    currentLeft = 6666-value;
  });

  await window.contract.methods.publicSalePrice.call().call().then(value  => {
    currentPublicPrice = web3.utils.fromWei(value,'ether')+"Ξ";
  });

  await window.contract.methods.allowListPrice.call().call().then(value  => {
    currentAllowlistPrice = web3.utils.fromWei(value,'ether')+"Ξ";
  });




  
  if(web3Modal && web3Modal.cachedProvider){
    try{
      provider = await web3Modal.connect();
      //addListeners(provider);

      library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();

      if(accounts.length>0){
        walletAddress=accounts[0]
      }
      }catch (error) {
         console.log(error.message)
          
      }
  }
  
  


  if(walletAddress===null){
    walletAddress="";
  }



  if(web3Modal && web3Modal.cachedProvider && walletAddress!==""){


      var ifAllowed =  checkIfInTree(walletAddress);

      currentAllowAmount =0
      //then ask the contract if this has been minted, if minted curretallowamount is zero, else if ifAllowed and not minted currentallowamount is 1
      if(ifAllowed){



          var claimed = await window.contract.methods.alreadyClaimed(walletAddress).call({from:walletAddress}, function(error, result){
                if(result===true){
                  currentAllowAmount =0
                  alreadyClaimed = true;
                }
                else{
                  currentAllowAmount =1
                }
       });
      }
      
      
      
       return {
                address: walletAddress,
                status: "Wallet Connected Successfully",
                leftAmount: currentLeft,
                publicPrice:currentPublicPrice ,
                allowlistPrice: currentAllowlistPrice,
                saleState: currentSaleState,
                allowlistState:currentAllowlistSaleState,
                allowAmount: currentAllowAmount,
                claimed: alreadyClaimed,
            };


       
      //return


      //const {address, status} = await connectWallet()
      
  }
  else{
   
      return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noopener noreferrer" href={`https://metamask.io/download.html`}>
             Please connect to an Ethereum compatible wallet
            </a>
          </p>
        </span>
      ),
      leftAmount: currentLeft,
      publicPrice:currentPublicPrice ,
      allowlistPrice: currentAllowlistPrice,
      saleState: currentSaleState,
      allowlistState:currentAllowlistSaleState,
      allowAmount: currentAllowAmount,
      claimed: alreadyClaimed,
    };

  }
  
  
};


