import React from "react";
import { Fragment, useEffect, useState, useContext } from "react";
import { getCurrentWalletConnected, mintNFT } from "./utils/interact.js";
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import LanguageContext from "./language-context";


const Minting = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [amount,setAmount] = useState("1");
  const [claimed, setClaimed] = useState("");
  const [show, setShow] = useState(false);
  const [leftAmount,setLeftAmount] = useState("????");
  const [publicPrice,setPublicPrice] = useState("???");
  const [allowlistPrice,setAllowlistPrice] = useState("0.03Ξ");
  const [saleState,setSaleState] = useState("????");
  const [allowlistState, setAllowlistState] = useState("????");
  const [showCase, setShowCase] = useState(<video className="w-full h-full object-center object-cover rounded-md" autoPlay={true} loop muted playsInline onContextMenu={(e)=>e.preventDefault()}  preload="auto">
        <source src="/img/mp4/sp3.mp4" type="video/mp4" />
        </video>)
  const [allowAmount,setAllowAmount] = useState("0");

  const [mintButtonClass, setMintButtonClass] = useState("w-full rounded-md border border-transparent px-5 py-3 bg-purple-600 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10");
  const [mintButtonValue, setMintButtonValue] = useState("Mint My Ride");
  const { language, setLanguage } = useContext(LanguageContext);
  


  useEffect(()=> {

    const fetchData = async() =>{




    const {address, status, leftAmount, publicPrice,allowlistPrice, saleState, allowlistState, allowAmount,claimed} = await getCurrentWalletConnected();
    

    setWallet(address)
    setStatus(status); 

    if(leftAmount!==""){
      setLeftAmount(leftAmount);
    }
    if(publicPrice!==""){
      setPublicPrice(publicPrice);
    }
    if(saleState!==""){
      setSaleState(saleState);
    }
   

    }
    fetchData();

     if(is_weChat()){
      setShowCase(<img src="img/gif/sp3.gif" alt="CyberRide" class="w-full h-full object-center object-cover rounded-md" /> )    
    }
    //addWalletListener();
    window.scrollTo(0, 0)

  }, [language]);

 
   function is_weChat(){
      var ua = navigator.userAgent.toLowerCase();
     
      if(ua.match(/MicroMessenger/i)=="micromessenger") {
      return true;
      } else {
      return false;
      }
    }

  
    const onMintPressed = async () => {
        
        
        const {success, status, leftAmount, price, saleState, web3, txnHash} = await mintNFT(amount);
        if(!success){
           setStatus(status);
           setShow(true);
        }

        if(leftAmount!==""){
          setLeftAmount(leftAmount);
        }
        if(price!==""){
          setPublicPrice(price);
        }
        if(saleState!==""){
          setSaleState(saleState);
        }

        
        if (success) {
         
          
            setMintButtonClass("btn loading w-full rounded-md border border-transparent px-5 py-3 bg-purple-600 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10");
            setMintButtonValue("Minting");
            if(web3!==""){
              const interval = setInterval(function() {
                console.log("Attempting to get transaction receipt...");
                web3.eth.getTransactionReceipt(txnHash, function(err, rec) {
                     
                     
                        if (rec) {
                        clearInterval(interval);
                        
                        let recStatus = rec['status']
                        if(recStatus===false){
                         
                          setStatus("😥 Something went wrong: with the transaction. Please check on EtherScan transaction from your wallet to view reason.");
                          setShow(true);
                        }
                        else{
                          setStatus(status);
                          setShow(true);
                        }
                        setMintButtonClass("w-full rounded-md border border-transparent px-5 py-3 bg-purple-600 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10");
                        setMintButtonValue("Mint My Ride");
                        if(amount!="" && leftAmount!="????"){
                          let d = parseInt(amount);
                          let e = parseInt(leftAmount);
                          setLeftAmount(String(e-d));
                          }
                        }
                      
                
                
                });


              }, 1000);
            }
            

      }
    };

 

  
  
  

  return (
    <div className="bg-white py-4 sm:py-2">
      <main>
 

    <div className="bg-white py-4 sm:py-2">
      <div className="relative sm:py-2">
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 inset-0">

      <div className="relative rounded-2xl px-6 py-4 bg-gradient-to-r from-purple-800 to-cyan-500 overflow-hidden shadow-xl sm:px-12 sm:py-4">
        
        <div className="relative ">
          
          <div className="mt-6 grid grid-cols-1 gap-x-10 gap-y-0 sm:gap-x-6 md:grid-cols-3 md:gap-y-0 lg:gap-x-8">
      <div className="group relative">
        
       
        
      </div>
      


       <div className="group relative p-4 md:p-8">
        <div className="bg-gray-200 rounded-md overflow-hidden relative aspect-h-1 aspect-w-1">
           
        

         {showCase}
        </div>
        
        
      </div>

      <div className="group relative">
        
       
        
      </div>
     

     

    </div>



          

          
        <div id="selection">
           <div className="Select">
    <div className="mt-2 pb-12 sm:pb-6">
    <div className="relative">
      <div className="absolute inset-0 h-1/2"></div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto">
          <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
            <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
              <dt className="order-2 mt-2 text-md leading-6 font-medium text-gray-500">
                Public Sale Status
              </dt>
              <dd className="order-1 text-2xl font-extrabold text-indigo-600">
               {saleState}
              </dd>
            </div>
            <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
              <dt className="order-2 mt-2 text-md leading-6 font-medium text-gray-500">
               Public Sale Price
              </dt>
              <dd className="order-1 text-2xl font-extrabold text-indigo-600">
             {publicPrice}
              </dd>
            </div>
            <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
              <dt className="order-2 mt-2 text-md leading-6 font-medium text-gray-500">
                Total Available 
              </dt>
              <dd className="order-1 text-2xl font-extrabold text-indigo-600">
             {leftAmount}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
      <div className="mt-2 mb-4 sm:mx-auto sm:max-w-2xl sm:flex">
      <form key={amount} className="min-w-0 flex-1">
      <div>
      <select
        id="amount"
        name="amount"
        className="mt-1 block w-full pl-3 pr-10 py-2 text-bold text-black border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base rounded-md"
        value={amount} onChange={(event) => setAmount(event.target.value)}
      ><option>Select a Mint Amount</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
      </select>
       </div>
      
      </form>
      <div className="mt-4  sm:mt-0 sm:ml-3">
       <button id="mintButton" onClick={onMintPressed} className={mintButtonClass}>
       {mintButtonValue}
      </button>
      </div>
      
    </div>
    </div>
          
         
      </div>
    </div>
  </div>
</div>
</div>
</div>
</main>

<div
        aria-live="assertive"
        className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className=" w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden ">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <div className="text-sm font-medium text-gray-900">{status}</div>
                    
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
  </div>
  );
};


export default Minting;
