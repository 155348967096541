import React from "react";

function Terms() {
  return (
    <main>
    <div className="terms">
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">


            <div>
            <h2 className="text-3xl font-extrabold text-gray-900">
              Terms & Conditions
            </h2>
            <p className="mt-4 text-lg text-gray-500">Last Updated: April 25, 2022</p>
            <br/>
          </div>




      <div className="mt-22 lg:mt-0 lg:col-span-2">
        <dl className="space-y-12">
          <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
             Interpretation and Definitions
            </dt>
            <dd className="mt-5 text-base text-gray-500">
              This website is operated by CyberRide LLC. Throughout the site "the Company", "CyberRide", "We", "Us" or "Our" in this Agreement refers to CyberRide LLC. These are the Terms and Conditions governing the use of this service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the service.
            </dd>

            <dd className="mt-5 text-base text-gray-500">
              We reserve the right to update, change or replace any part of these Terms of services without prior written notice at any time, and it is your responsibility to periodically review these Terms of Uses to stay informed of updates. Any changes to the Terms will be in effect as of the "last updated" referenced on the site. Your continued use of this site after the last updated dates will constitute your acceptance of the agreement. By visiting the website of our products merchants, in particular www.opensea.io, you also engage and agree to be bound by their terms and conditions.
            </dd>
            <dd className="mt-5 text-base text-gray-500">
              This website is for informational purposes and serves as a binding contract for purchasers of CyberRide NFTs.
            </dd>
          </div>
           <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
             The CyberRide NFT
            </dt>
            <dd className="mt-5 text-base text-gray-500">
             CyberRide is a generative collection of digital artwork (NFTs) stored on the Ethereum Network. Users are solely responsible for the safety and the management of their own private digital assets, which include but are not limited to managing Ethereum Wallets, validating all transactions and contracts generated by OpenSea.io prior to and after purchases.
            </dd>

            <dd className="mt-5 text-base text-gray-500">
              Users certify through purchase that they understand that, as the CyberRide smart contract runs on the Ethereum network and is bound by their system and terms, there is no ability to undo, reverse, or restore any transactions. To obtain the NFT, you will need to have a web browser and an Ethereum wallet that is compatible with the Non-Fungible Token (NFT) standard on the Ethereum network. We do not own nor control your web browser, your Ethereum wallet, the Ethereum network, or any other third-party site, product, or service that you might access, visit or use for the purpose of enabling you to perform a transaction. We are not liable for the acts or omissions of any such third parties, nor will we be liable for any damage that you may suffer as a result of your transactions or any other interaction with any such third parties.
            </dd>

            <dd className="mt-5 text-base text-gray-500">
              Any connected services included on this website are provided "as is" and "as available" without any warranty of any kind. Use of this website constitutes your agreement that you are accepting sole responsibility for any and all transactions involving the CyberRide digital collectibles.
            </dd>
            <dd className="mt-5 text-base text-gray-500">
              By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence. You may not use our products and/or services for any illegal or unauthorized purpose, nor may you, in the use of the service, violate any laws in your jurisdiction (including but not limited to copyright laws).
            </dd>
            <dt className="mt-6 text-lg leading-6 font-bold text-gray-900">
            Future Generation Rides Granted to Gen-1
            </dt>
             <dd className="mt-5 text-base text-gray-500">
             As mentioned on CyberRide.io, each CyberRide Gen-1 NFT in your wallet will grant you one free CyberRide on every future CyberRide release. Note that owners will need to pay the gas fee on their own.              </dd>
              <dd className="mt-5 text-base text-gray-500">
             This setup will be valid as long as CyberRide LLC is operating and releasing new generations of CyberRide NFTs. CyberRide LLC will work to the best of its ability to provide future generation releases. Howerver, CyberRide LLC is not guaranteeing a specific number of generations (like 2077) to be released.
              </dd>
             


              <dt className="mt-6 text-lg leading-6 font-bold text-gray-900">
             Using Rides in Other Games and Metaverse Worlds
            </dt>
             <dd className="mt-5 text-base text-gray-500">
         Screenshots and video clips on CyberRide.io, the CyberRide official Twitter account, the CyberRide official Discord server, and the CyberRide official social media accounts are to showcase how CyberRide NFT would look/interact in various open-world games and metaverse worlds. CyberRide LLC does not guarantee that CyberRide NFT will be natively supported by games and Metaverse worlds. We will provide 3D models and dedicated APIs to streamline the import process. But ultimately, it is the specific game or metaverse world's choice if they can support CyberRide NFT out of the box. We expect the first Metaverse world with full CyberRide support to be CyberRide World in NFT Worlds as CyberRide LLC has complete control over implementation.       </dd>
               <dd className="mt-5 text-base text-gray-500">
              CyberRide LLC will provide the 3D model files for download for all CyberRide NFT owners to use in any games or Metaverse of their choosing. Owners should obey the specific game's End User License Agreement and community guidelines. CyberRide LLC is not responsible for any violation caused by CyberRide NFT owners if they violate any guideline and license agreement of the game or Metaverse world.
               </dd>

               <dd className="mt-5 text-base text-gray-500">
             CyberRide and CyberRide LLC are in no way associated with, endorsed by, or a partner of the parties creating the example games and Metaverse shown on the site. These parties include but are not limited to Roblox, Decentraland, Decentraland Foundation, The Sandbox, Animoca Brands, Cryptovoxel, Minecraft, Mojang, Microsoft, Veloren, Meta, Larva Labs, Yuga Labs, NFT Worlds, or any related parties.                </dd>
          </div>

           <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
            Ownership
            </dt>
            <dd className="mt-5 text-base text-gray-500">
            When you purchase your CyberRide NFT, You Own the NFT: the ownership is transferred to you on the Ethereum blockchain for that individual ride. Ownership of the NFT is ruled by the smart contract and the Ethereum Network terms. We, CyberRide LLC, have no ability to stop, modify, freeze, seize or alter the ownership of any CyberRide NFT.
            </dd>
            <dt className="mt-6 text-lg leading-6 font-bold text-gray-900">
            Personal Usage
            </dt>

            <dd className="mt-5 text-base text-gray-500">
              Subject to your continued acceptance with these terms, CyberRide LLC allows you a worldwide, non-exclusive, non-transferable, royalty-free license to use, copy and display the purchased art, along with any derivative artwork or extensions that you could create or use, limited by the following purposes : (i) for the User's personal, non-commercial use; (ii) as part of a marketplace that allows the purchase and sales of you CyberRide NFT, as long as the marketplace cryptographically verifies that you are the owner, to ensure that only the actual owner has the right to display the art; or (iii) as the party of a third-party marketplace or application that allows the owner to transfer its CyberRide NFT and permits the inclusion, involvement, or participation of the Platform Asset.
            </dd>


            <dt className="mt-6 text-lg leading-6 font-bold text-gray-900">
            Commercial Usage
            </dt>

            <dd className="mt-5 text-base text-gray-500">
             Subject to the continued compliance with the terms and conditions (only where the CyberRide LLC has the right to offer the same AND for only as long as the owner of CyberRide NFTs still owns that particular NFT), the owner is granted with an unlimited, worldwide, exclusive, license to use, copy, and display the art underlying CyberRide NFT for the purpose of creating derivative works based upon the CyberRide Asset ("Commercial Use").
            </dd>
            <dd className="mt-5 text-base text-gray-500">
              Examples of such Commercial Use would be the use of the CyberRide Asset to produce and sell merchandise products displaying copies of the art underlying the Platform Asset. For the sake of clarity, nothing in this Section will be deemed to restrict the owner from (i) owning or operating a marketplace that permits the use and sale of CyberRide Assets generally, provided that the marketplace cryptographically verifies each CyberRide Asset owner's rights to display the art for their CyberRide Asset to ensure that only the actual owner can display the CyberRide Asset; (ii) owning or operating a third-party website or application that permits the inclusion, involvement, or participation of CyberRide Assets generally, provided that the third-party website or application cryptographically verifies each CyberRide Asset owner's rights to display the art for their CyberRide Asset to ensure that only the actual owner can display the art, and provided that the art is no longer visible once the owner of the purchased CyberRide Asset leaves the website/application; or (iii) earning revenue from any of the foregoing; or (iv) making use of the 3D model file provided to the owner to host and create application or experience in any open world games and the metaverse; (v) physically printing out 3D Modeling figures is currently prohibited as we are reserving that functionality for community airdrop.
            </dd>

             <dd className="mt-5 text-base text-gray-500">
             However, you cannot use the artwork in connection with images of hatred, violence, or any other inappropriate behavior. The License granted above only applies to the extent that you continue to own the particular Cyberride NFT. If at any time you trade, donate, give away, transfer, or otherwise dispose of your NFT for any reason, the License granted above will immediately expire, without notice, and you will have no further right in or to the artwork of this NFT.
            </dd>
          </div>


           <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
             The CyberRide IP
            </dt>
            <dd className="mt-5 text-base text-gray-500">
             Other than the rights to the NFT, nothing gives you any rights to any other trademarks or other intellectual property rights belonging to CyberRide LLC, including, without limitation, CyberRide, CYBERRIDE, CyberRide NFTs, CyberRide Gen-1, CyberRide LLC and the associated logos. All of these rights are expressly reserved in the name of CyberRide LLC.
            </dd>

            <dd className="mt-5 text-base text-gray-500">
              Further, CyberRide LLC reserves the right to use ANY Ride, including print or digital advertising or any purely creative media (including short film, anime, etc.) in support of the CyberRide community and message.
            </dd>
          </div>
          
        </dl>
      </div>

      



            </div>
          </div>
        </div>
    </div>
   </main>
  );
}

export default Terms;
