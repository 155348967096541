import React from "react";

function Privacy() {
  return (
    <main>
    <div className="terms">
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">


            <div>
            <h2 className="text-3xl font-extrabold text-gray-900">
              Privacy Policy
            </h2>
            <p className="mt-4 text-lg text-gray-500">Last Updated: Feb 9, 2022</p>
            <br/>
          </div>




      <div className="mt-22 lg:mt-0 lg:col-span-2">
        <dl className="space-y-12">
          <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
             Privacy Policy
            </dt>
            <dd className="mt-5 text-base text-gray-500">
              At CyberRide LLC, accessible from CyberRide.io, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by CyberRide LLC and how we use it.




            </dd>

            <dd className="mt-5 text-base text-gray-500">
            If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.

            </dd>
            <dd className="mt-5 text-base text-gray-500">
              This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in CyberRide.io. This policy is not applicable to any information collected offline or via channels other than this website. 
            </dd>
          </div>
           <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
             Consent
            </dt>
            <dd className="mt-5 text-base text-gray-500">
           Consent

By using our website, you hereby consent to our Privacy Policy and agree to its terms.

            </dd>

            
          </div>

           <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
            
          Information we collect

            </dt>
            <dd className="mt-5 text-base text-gray-500">
            The only information you will provide is your public Ethereum wallet address via MetaMask. We do not collect any of your personal information on the site. 

            </dd>


            <dt className="mt-6 text-lg leading-6 font-bold text-gray-900">
            How we use your information
            </dt>

            <dd className="mt-5 text-base text-gray-500">
             Your public Ethereum address provided via MetaMask will be used as the address to collect the newly minted NFT when you mint. 
            </dd>



            
          </div>


           <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
             Log Files
            </dt>
            <dd className="mt-5 text-base text-gray-500">
             CyberRide LLC follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
            </dd>
          </div>


           <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
            Advertising Partners Privacy Policies
            </dt>
            <dd className="mt-5 text-base text-gray-500">
            You may consult this list to find the Privacy Policy for each of the advertising partners of CyberRide LLC.
            </dd>

            <dd className="mt-5 text-base text-gray-500">
           Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on CyberRide, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
            </dd>



            <dd className="mt-5 text-base text-gray-500">
            
            Note that CyberRide LLC has no access to or control over these cookies that are used by third-party advertisers.
            </dd>


          </div>


          

          <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
            Third Party Privacy Policies
            </dt>
            <dd className="mt-5 text-base text-gray-500">
          CyberRide LLC's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.


            </dd>

            <dd className="mt-5 text-base text-gray-500">
           You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
            </dd>
          </div>





           <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
            CCPA Privacy Rights (Do Not Sell My Personal Information)
            </dt>
            <dd className="mt-5 text-base text-gray-500">
         Under the CCPA, among other rights, California consumers have the right to:
</dd> <dd className="mt-5 text-base text-gray-500">
Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
</dd> <dd className="mt-5 text-base text-gray-500">
Request that a business delete any personal data about the consumer that a business has collected.
</dd> <dd className="mt-5 text-base text-gray-500">
Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
</dd> <dd className="mt-5 text-base text-gray-500">
If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.

            </dd>
          </div>


          <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
            GDPR Data Protection Rights
            </dt>
            <dd className="mt-5 text-base text-gray-500">
        We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
</dd> <dd className="mt-5 text-base text-gray-500">
The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
</dd> <dd className="mt-5 text-base text-gray-500">
The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
</dd> <dd className="mt-5 text-base text-gray-500">
The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
</dd> <dd className="mt-5 text-base text-gray-500">
The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
</dd> <dd className="mt-5 text-base text-gray-500">
The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
</dd> <dd className="mt-5 text-base text-gray-500">
The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
</dd> <dd className="mt-5 text-base text-gray-500">
If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.

            </dd>
          </div>


          <div>
            <dt className="text-2xl leading-6 font-bold text-gray-900">
            Children's Information


            </dt>
            <dd className="mt-5 text-base text-gray-500">
       
Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
</dd> <dd className="mt-5 text-base text-gray-500">
CyberRide LLC does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
            </dd>
          </div>






          
        </dl>
      </div>

      



            </div>
          </div>
        </div>
    </div>
   </main>
  );
}

export default Privacy;
