import React from "react";

function Error() {
  return (
<div className="bg-white min-h-full flex flex-col lg:relative">
  <div className="flex-grow flex flex-col">
    <main className="flex-grow flex flex-col bg-white">
      <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 pt-10 sm:pt-16">
          <a href="/" className="inline-flex">
            <span className="sr-only">Workflow</span>
            
          </a>
        </div>
        <div className="flex-shrink-0 my-auto py-16 sm:py-32">
          <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">404 error</p>
          <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page not found</h1>
          
          <div className="mt-6">
            <a href="/" className="text-base font-medium text-indigo-600 hover:text-indigo-500">Go back <span aria-hidden="true"> &rarr;</span></a>
          </div>
        </div>
      </div>
    </main>
    <footer className="flex-shrink-0 bg-white">
      <div className="mx-auto max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
        <nav className="flex space-x-4">
          
        </nav>
      </div>
    </footer>
  </div>
  <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-20 lg:w-1/2 py-16 ">
   <video className="absolute inset-0 h-full w-full object-cover rounded-md"  autoPlay={true} loop muted playsInline  onContextMenu={(e)=>e.preventDefault()}  preload="auto">
        <source src="img/mp4/sp1.mp4" type="video/mp4" />
    </video>
  </div>
</div>
  
  );
}

export default Error;
