import React from "react";
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { Navigate } from 'react-router';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  Minting,
  Terms,
  Provenance,
  Error,
  Privacy,
  Allowlist,
  Faq
} from "./components";

import LanguageContext from "./components/language-context";




const App = () => {



  const [language, setLanguage] = useState("");
  const value = { language, setLanguage };


  return (<Router>
   <LanguageContext.Provider value={value}><Navigation />
     </LanguageContext.Provider>
    
     
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/info" element={<Home />} />
      <Route path="/minting" element={<LanguageContext.Provider value={value}> <Minting /> </LanguageContext.Provider>}  />
      <Route path="/terms" element={<Terms />} />
      <Route path="/provenance" element={<Provenance />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/allowlist" element={<LanguageContext.Provider value={value}>
      <Allowlist />
     </LanguageContext.Provider>
      } />
      <Route path="/gallery/*" element={<Navigate to ="/" />}/>
      <Route path="/faq" element={<Faq />} />
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<Navigate to ="/error" />}/>
    </Routes>
    <Footer />
  </Router>)
}

ReactDOM.render(
  <React.StrictMode>
  <App/>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
