/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
  {
    question: "WEN mint?",
    answer:
      "Feb 16, 2023 2pm PST / 5pm EST / 10pm UTC / 6am (Feb 17) China Standard Time",
  },

  {
    question: "How much is each ride?",
    answer:
      "For the Allowlist sale: each ride will cost 0.02Ξ with 1 ride available for each wallet address in the allowlist.  For the public sale: Each ride will cost 0.025Ξ with a maximum of 5 rides per transaction.",
  },

   {
    question: "How long will the allowlist sale last?",
    answer:
      "Allowlist sale will begin 6 hours ahead of the public sale. This means 6 hours for the allowlist winner to mint to avoid gas war. If allowlist reserved are not minted before the public sale opens, it is not guaranteed that allowlist rides will be available for mint. Rides will be minted on a first-come and first-serve basis during the public sale. ",  },


  {
    question: "How can I get in the allowlist?",
    answer: "We plan to premint.xyz and alphabot to fairly distribute allowlist spots. Please follow our Twitter for details.",

  },


  {
    question: "What's an NFT?",
    answer:
      "NFT stands for 'non-fungible token'.  It's a unique, one-of-a-kind digital item that users can buy, own, and trade. For example, a bitcoin is fungible — trade one for another bitcoin, and you'll have exactly the same thing. A one-of-a-kind trading card, however, is non-fungible.",
  },

  {
    question: "What is Ethereum?",
    answer:
      "Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications. Learn more about Ethereum: https://ethereum.org/en/"  },


   {
    question: "What is Metamask?",
    answer:
      "Metamask is a crypto wallet & gateway to blockchain apps, and it also stores your Ether. It's needed to purchase and mint NFTs like CyberRide. Having a wallet gives you an Ethereum address which is also where your NFT is stored. Learn more about Metamask: https://metamask.io/"  
    },
    {
    question: "What is the CyberRide?",
    answer:
      "The CyberRide Gen-1 is a collection of unique 3D voxel rides designed to be your first ride in the Metaverse. For a flat rate of 0.1Ξ, you can randomly mint a CyberRide as a non-fungible token (NFT) tradable on OpenSea."

    },

   {
    question: "Why Gen-1?",
    answer:
      "Gen-1 is the only generation of CyberRide with a 100% unlock rate for every future release. As the Metaverse evolves, CyberRide will evolve with it. From 3D voxel rides (Gen-1), we will evolve CyberRide to more futuristic and interactive CyberRide Generations (Gen-2, and eventually Gen-2077). Each Gen-1 ride will unlock one free Gen-N ride on every future CyberRide release. Owners will only have to pay the gas fee to mint next-generation CyberRide if they own a Gen-1 CyberRide."  },


   {
    question: "What will you get?",
    answer:
      "Owning a Gen-1 CyberRide means you possess a unique ride for the Metaverse as well as a key to all future CyberRide Generations. The ride will also serve as a key to unlock exclusive benefits and offerings in the CyberRide community. ",
  },


   {
    question: "What blockchains is CyberRide on?",
    answer:
      "All rides are parked on the Ethereum blockchain with ERC-721 standard.",
  },


  {
    question: "Where does my NFT go after I purchase a CyberRide?",
    answer:
      "Your CyberRide NFT will appear in whatever ethereum wallet you used to purchase the CyberRide. You can view your CyberRide in OpenSea after you mint. Our website will also provide a gallery and individual ride page once ride images are revealed.",
  },



  {
    question: "Do you have a roadmap?",
    answer:
      "Yeap. You can see the detailed roadmap on our site: https://cyberride.io/#roadmap",
  },



  {
    question: "Is CyberRide a good investment?",
    answer:
      "As with all things, please do your own research before purchasing a CyberRide. We have high hope for the CyberRide project and will continuously build to bring more value to the project. However, as there are many factors in the world that are beyond our control, we cannot guarantee that purchasing a CyberRide will yield profit. As with anything in life, don't spend money you can't afford to not have.",
  },



  {
    question: "How can you prove the mint is random?  ",
    answer:
      "We implement a provenance structure to ensure fairness. In fact, the provenance hash is calculated and locked even before smart contract deployment to ensure fairness. The starting index used to rotate the sequence of images is determined by the block number of the first mint. We do not have control over this in any way. For details on how this works, please check: https://cyberride.io/provenance"
  },



  {
    question: "What are CyberRide ultimate objectives?  ",
    answer:
      "We believe we have a unique vision of how NFTs should work to ultimately become the building block of an open, interactive, and interconnected Metaverse in the future. Each Generation of CyberRide we build and ship is an example of that vision combined with the restraint of the technology and resources we have at that point in time. Each generation of CyberRide will not be perfect, but soon you will see more and more of our vision become a reality as CyberRide evolves. Just wait and see for yourself.",
  },

  // More questions...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function faq() {
  return (
    <main>
    <div className="provenance">
        
<div className="bg-white">
  <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
    <div className="lg:grid lg:grid-cols-3 lg:gap-8">

      <div>
        <h2 className="text-3xl font-extrabold text-gray-900">
          FAQs
        </h2>
        <p className="mt-4 text-lg text-gray-500">Content updated: Jan 5, 2023</p>
        <br/>
      </div>


       <div className="mt-22 lg:mt-0 lg:col-span-2">
       <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>


        

      
</div>



</div>
</div>
</div>

    </div>
   </main>


  )
}

export default faq;