import React from "react";
import { useEffect, useState } from "react";
const mystyle = {
      right: "50%",
      border: "2px solid #22D3EE",
      borderRadius:"1%"
      
    };

const mystyle2 = {
      left: "50%",
      border: "2px solid #22D3EE",
      borderRadius:"1%"
      
    };



const Home = (props) => {


const [showCase3, setShowCase3] = useState(<video className="relative mx-auto rounded-md"  width="490"  autoPlay={true} loop muted  playsInline  onContextMenu={(e)=>e.preventDefault()} preload="auto">
        <source src="img/mp4/sp3.mp4" type="video/mp4"/>
        </video>)

const [showCase1, setShowCase1] = useState(<video className="relative mx-0 rounded-md" width="490"  autoPlay={true} loop muted playsInline  onContextMenu={(e)=>e.preventDefault()}  preload="auto">
        <source src="img/mp4/sp1.mp4" type="video/mp4" />
        </video>)

const [showCase2, setShowCase2] = useState(<video className="relative mx-0 rounded-md" width="490"  autoPlay={true} loop muted  playsInline  onContextMenu={(e)=>e.preventDefault()}  preload="auto">
        <source src="img/mp4/sp2.mp4" type="video/mp4" />
        </video>)

const [showCase4, setShowCase4] = useState(<video className="w-full h-full object-center object-cover rounded-md"  autoPlay={true} loop muted  playsInline  onContextMenu={(e)=>e.preventDefault()}  preload="auto">
        <source src="img/mp4/4.mp4" type="video/mp4" />
        </video>)

useEffect(async () => {
    
    
    if(is_weChat()){
      setShowCase1(<img src="img/gif/sp1.gif" alt="CyberRide1" className="relative mx-0 rounded-md"  width="490" />)
      setShowCase2(<img src="img/gif/sp2.gif" alt="CyberRide2" className="relative mx-0 rounded-md"  width="490" />)
      setShowCase3(<img src="img/gif/sp3.gif" alt="CyberRide3" className="relative mx-auto rounded-md"  width="490" /> )
      setShowCase4(<img src="img/gif/4.gif" alt="CyberRide4" className="w-full h-full object-center object-cover rounded-md"  width="490" /> )  

    }

    

  }, []);

   function is_weChat(){
      var ua = navigator.userAgent.toLowerCase();
     
      if(ua.match(/MicroMessenger/i)=="micromessenger") {
      return true;
      } else {
      return false;
      }
    }

    return (
    <main>
    <div className="home">
      <div>
     
      <div className="relative bg-white">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"></div>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <img className="h-full w-full object-cover" src="img/webp/hero.webp" alt="cyberride"/>
              <div className="absolute inset-0 bg-gradient-to-r from-purple-600"></div>
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-4xl font-extrabold tracking-normal sm:text-5xl lg:text-6xl">
             

                <img className="h-8 w-auto sm:h-8" src="img/svg/cyberride-w.svg" alt=""/>
                 <img className="mt-2 h-8 w-auto sm:h-8" src="img/svg/Gen-1w.svg" alt=""/>
                 

              </h1>

              <p className="mt-6 max-w-md text-xl font-bold text-gray-50 sm:max-w-xl" >

                Your First Unique Ride For Any Metaverse
              </p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                        
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    


<div className="py-24 bg-gray-100 overflow-hidden lg:py-24 bg-gray-100" id='ride' >
  <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl " >
    <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="relative">
        <h3 className="text-2xl font-extrabold text-gray-900 tracking-normal lg:text-3xl" >
          What is the CyberRide?
        </h3>
        <p className="mt-3 text-xl text-gray-500 tracking-wide lg:text-lg" >
         
The CyberRide Gen-1 is a collection of unique 3D voxel rides designed to be your first ride in the Metaverse. </p>
<p className="mt-3 text-xl text-gray-500 tracking-wide lg:text-lg">
You can randomly mint a CyberRide as a non-fungible token (NFT) tradable on OpenSea. 
        </p>

        <dl className="mt-10 space-y-10">
          <div className="relative">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-400 text-white">
              
               <i className="fas fa-biking"></i>
              </div>
              <p className="ml-16 text-lg leading-6 font-bold text-gray-900 lg:text-base">The Unique Ride</p>
            </dt>
            <dd className="mt-2 ml-16 text-lg text-gray-500 tracking-wide lg:text-base">
             Each ride is uniquely generated based on hundreds of prototypes, attributes, materials, and color palettes. No two rides are the same. 
            </dd>
          </div>

          <div className="relative">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-400 text-white">
          
                <i className="fab fa-ethereum"></i>
              </div>
              <p className="ml-16 text-lg leading-6 font-bold text-gray-900 lg:text-base">The Blockchain Ride</p>
            </dt>
            <dd className="mt-2 ml-16 text-lg text-gray-500 tracking-wide lg:text-base">
              All rides are parked on the Ethereum blockchain with ERC-721 standard. Rendered images and metadata are stored on IPFS.

            </dd>
          </div>

          <div className="relative">
            <dt>
              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-400 text-white">
            
                 <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
</svg>
              </div>
              <p className="ml-16 text-lg leading-6 font-bold text-gray-900 lg:text-base">The 3D Ride</p>
            </dt>
            <dd className="mt-2 ml-16 text-lg text-gray-500 tracking-wide lg:text-base">
              All rides are in 3D Voxel format. Perfect for open-world games and the Metaverse.
            </dd>
          </div>
        </dl>
      </div>

      <div className="mt-10 -mx-4 relative lg:mt-0 mr-0.5 ml-0.5" aria-hidden="true">
       
      
        {showCase1}
      </div>
    </div>

    
    <div className="relative mt-12 sm:mt-16 lg:mt-24">
      <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div className="lg:col-start-2">
          <h3 className="text-2xl font-extrabold text-gray-900 tracking-normal sm:text-3xl">
           Why Gen-1?
          </h3>
          <p className="mt-3 text-xl text-gray-500 tracking-wide lg:text-lg">
          As the Metaverse evolves, CyberRide will evolve with it.           </p>

           <p className="mt-3 text-xl text-gray-500 tracking-wide lg:text-lg">
 Gen-1 is the only generation of CyberRide with a 100% unlock rate for every future release.
 </p>
          <dl className="mt-10 space-y-10">
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-400 text-white">
                
                 <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z" />
</svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-bold text-gray-900 tracking-wide lg:text-base">From Gen-1 to Gen-2077: </p>
              </dt>
              <dd className="mt-2 ml-16 text-lg text-gray-500 tracking-wide lg:text-base">
              From 3D voxel rides (Gen-1), we will evolve CyberRide to more futuristic and interactive CyberRide Generations (Gen-2, and eventually Gen-2077). 
              </dd>
            </div>

            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-400 text-white">
             
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
</svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-bold text-gray-900 tracking-wide lg:text-base">A key to all future releases</p>
              </dt>
              <dd className="mt-2 ml-16 text-lg text-gray-500 tracking-wide lg:text-base">
             To preserve the unique history of CyberRide evolution, each Gen-1 ride will unlock one free Gen-N ride on every future CyberRide release.
              </dd>
            </div>
          </dl>
        </div>

        <div className="mt-10 -mx-4 relative lg:mt-0  mr-0.5 ml-0.5 lg:col-start-1">
        {showCase2}
          
        </div>
      </div>
    </div>
  </div>
</div>


  <div className="bg-white">
  <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
    <div className="md:flex md:items-center md:justify-between">
     
      <h3 className="text-2xl font-extrabold text-gray-900 tracking-normal lg:text-3xl">
           What will you get?
          </h3>
    </div>
    <br/>
    <p className="mt-3 text-xl text-gray-500 tracking-wide lg:text-lg">Owning a Gen-1 CyberRide means you possess a unique ride for the Metaverse as well as a key to all future CyberRide Generations. The ride will also serve as a key to unlock exclusive benefits and offerings in the CyberRide community.
    </p>

    <p className="mt-3 text-xl text-gray-500 tracking-wide lg:text-lg">Owning a Gen-1 CyberRide will grant access to the following:</p>
    <div className="mt-6 grid grid-cols-2 gap-x-10 gap-y-10 lg:gap-x-6 md:grid-cols-3 md:gap-y-10 lg:gap-x-8">
      <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden relative aspect-h-1 aspect-w-1">
          <img src="img/webp/4.webp" alt="CyberRide." className="w-full h-full object-center object-cover" />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
          
           High-Resolution Lossless Render File
          
        </h3>
        <p className="mt-1 text-lg text-gray-500 tracking-wide lg:text-base">1080 x 1080 high-resolution lossless render file. Perfect for profile pictures on any platform and sharing on social media.  </p>
        
      </div>

       <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden relative aspect-h-1 aspect-w-1">
           
        {showCase4}

         
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
         
            3D OBJ file
          
        </h3>
        <p className="mt-1 text-lg text-gray-500 tracking-wide lg:text-base">Owners can import the 3D OBJ files to the Metaverse, builder tools of open-world games, game engines, and 3D modeling software.</p>
        
       
      </div>


       <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden relative aspect-h-1 aspect-w-1">
          <img src="img/webp/nextgen.webp" alt="CyberRide" className="w-full h-full object-center object-cover" />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
      
            100% Unlock Rate for All Future CyberRide Releases
          
        </h3>
        <p className="mt-1 text-lg text-gray-500 tracking-wide lg:text-base"> Each CyberRide Gen-1 NFT in your wallet will grant one free CyberRide on every future release. You will only have to pay the gas fee. </p>
       
      </div>


       <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden relative aspect-h-1 aspect-w-1">
          <img src="img/webp/pass.webp" alt="CyberRide" className="w-full h-full object-center object-cover" />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
      
           CyberRiders Club Membership
          
        </h3>
        <p className="mt-1 text-lg text-gray-500 tracking-wide lg:text-base"> Each CyberRide NFT doubles as your membership pass to the CyberRiders Club. This includes exclusive access to our private Discord, IRL events, private game servers, and other exclusive collaborations.       </p>
       
      </div>


    </div>

    <div className="mt-8 text-sm md:hidden">
     
    </div>
  </div>
</div>






<div className="bg-white" id='testdrive'>

  <div className="max-w-2xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
    <div className="md:flex md:items-center md:justify-between">
    
     <h3 className="text-2xl font-extrabold text-gray-900 tracking-normal lg:text-3xl">
          Designed for the Metaverse:
          </h3>
    </div>

      <p className="mt-8 text-xl text-gray-500 tracking-wide lg:text-lg">
      CyberRide is designed to be accessible across different platforms and experiences in the Metaverse. Interoperability is built into CyberRide from day one. 
        </p>

        <p className="mt-8 text-xl text-gray-500 tracking-wide lg:text-lg">
      We imagine the ideal flow would be the digital item (including the 3D file, attribute data, script, etc.) to be available at your fingertip when you log in with your wallet, similar to the experience in Ready Player One.  
        </p>


        <p className="mt-8 text-xl text-gray-500 tracking-wide lg:text-lg">
Although the ideal flow is not yet a reality, we successfully imported and test-drove CyberRide in various platforms and worlds. Check out how they look in some of your favorite Metaverse worlds and open-world games.
        </p>

    <h4 className="mt-12 text-2xl font-extrabold tracking-normal text-gray-900 tracking-wide lg:text-xl">The Metaverse</h4>
    <div className="mt-6 grid grid-cols-2 gap-x-10 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
      <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden  relative aspect-h-1 aspect-w-1">
          <img src="img/webp/roblox.webp" alt="Roblox" className="w-full h-full object-center object-cover" />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide sm:text-base">
          <a >
            <span className="absolute inset-0"></span>
           Roblox 
          </a>
        </h3>
        <p className="mt-1 text-sm text-gray-500"> </p>
        
      </div>

       <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden  relative aspect-h-1 aspect-w-1">
          <img src="img/webp/dl.webp" alt="Decentraland" className="w-full h-full object-center object-cover"  />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
          <a >
            <span className="absolute inset-0"></span>
           Decentraland
          </a>
        </h3>

        
      </div>


       <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden  relative aspect-h-1 aspect-w-1">
          <img src="img/webp/sandbox.webp" alt="The Sandbox" className="w-full h-full object-center object-cover" />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
          <a>
            <span className="absolute inset-0"></span>
            The Sandbox
          </a>
        </h3>
      
        
      </div>


      <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden relative aspect-h-1 aspect-w-1">
          <img src="img/webp/cv.webp" alt="Cryptovoxels" className="w-full h-full object-center object-cover" />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
          <a>
            <span className="absolute inset-0"></span>
            Cryptovoxels
          </a>
        </h3>
       
       
      </div>

  

    </div>

<h4 className="mt-8 text-2xl font-extrabold tracking-normal text-gray-900 tracking-wide lg:text-xl">Open World Games, VR & AR</h4>
    <div className="mt-6 grid grid-cols-2 gap-x-10 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
      <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden relative aspect-h-1 aspect-w-1">
          <img src="img/webp/teardown.webp" alt="Teardown" className="w-full h-full object-center object-cover" />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
          <a >
            <span className="absolute inset-0"></span>
          Teardown
          </a>
        </h3>
       
        
      </div>

       <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden relative aspect-h-1 aspect-w-1">
          <img src="img/webp/vl.webp" alt="Veloren" className="w-full h-full object-center object-cover" />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
          <a >
            <span className="absolute inset-0"></span>
           Veloren
          </a>
        </h3>
       
        
      </div>

      <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden  relative aspect-h-1 aspect-w-1">
          <img src="img/webp/vr.webp" alt=" Virtual Reality (VR)" className="w-full h-full object-center object-cover" />
        
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
          <a >
            <span className="absolute inset-0"></span>
           Virtual Reality (VR)
          </a>
        </h3>
  
        
      </div>

<div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden relative aspect-h-1 aspect-w-1">
          <img src="img/webp/ar.webp" alt="Augmented Reality (AR)" className="w-full h-full object-center object-cover" />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
          <a >
            <span className="absolute inset-0"></span>
           Augmented Reality (AR)
          </a>
        </h3>
 
      </div>

    </div>


    <h4 className="mt-8 text-2xl font-extrabold tracking-normal text-gray-900 tracking-wide lg:text-xl">Interacting with other NFTs</h4>

     <div className="mt-6 grid grid-cols-2 gap-x-10 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
      <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden relative aspect-h-1 aspect-w-1">
          <img src="img/webp/meebits3.webp" alt="Meebits" className="w-full h-full object-center object-cover" />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
          <a >
            <span className="absolute inset-0"></span>
          Meebits #6948
          </a>
        </h3>
       
        
      </div>

       <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden relative aspect-h-1 aspect-w-1">
          <img src="img/webp/meebits4.webp" alt="Veloren" className="w-full h-full object-center object-cover" />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
          <a >
            <span className="absolute inset-0"></span>
            Meebits #6948
          </a>
        </h3>
       
        
      </div>

      <div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden  relative aspect-h-1 aspect-w-1">
          <img src="img/webp/kongz1.webp" alt=" Virtual Reality (VR)" className="w-full h-full object-center object-cover" />
        
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
          <a >
            <span className="absolute inset-0"></span>
            CyberKongz VX #4572
          </a>
        </h3>
  
        
      </div>

<div className="group relative">
        <div className="bg-gray-200 rounded-md overflow-hidden relative aspect-h-1 aspect-w-1">
          <img src="img/webp/kongz2.webp" alt="Augmented Reality (AR)" className="w-full h-full object-center object-cover" />
        </div>
        <h3 className="mt-4 text-lg font-bold text-gray-700 tracking-wide lg:text-base">
          <a >
            <span className="absolute inset-0"></span>
            CyberKongz VX #4572
          </a>
        </h3>
 
      </div>

    </div>







    </div> 




  </div>



<section>
    <div className="bg-white py-8" id='roadmap'>
     
    <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24 py-16 px-4 ">
      <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8">
        
        <p className="text-3xl font-semibold md:text-4xl text-purple-500 leading-normal md:leading-relaxed mb-2 lg:text-3xl" >ROADMAP</p>
        
        
       <div className="mt-6">
              <a href="https://twitter.com/CyberRideNFT" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-500 to-cyan-500 bg-origin-border hover:bg-indigo-700">
               Follow on Twitter
              </a>
            </div>
      </div>
      <div className="ml-0 md:ml-12 lg:w-2/3 sticky">
        <div className="container mx-auto w-full h-full">
          <div className="relative wrap overflow-hidden p-2 h-full tracking-normal">
            <div className="border-2-2 border-green-555 absolute h-full border" style={mystyle}
              ></div>
            <div className="border-2-2 border-green-555 absolute h-full border"
            style={mystyle2}></div>

            <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="order-1 w-5/12 px-1 py-4 text-right">
                
                <h4 className="mb-3 font-bold text-black text-lg md:text-2xl">33%</h4>
                <p className="text-base md:text-base leading-snug text-black text-opacity-100 tracking-wide">
                 Legendary 1/1s reveal.
                </p>
              </div>
            </div>
            <div className="mb-8 flex justify-between items-center w-full right-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="order-1  w-5/12 px-1 py-4 text-left">
         
                <h4 className="mb-3 font-bold text-black text-lg md:text-2xl"> 66%</h4>
                <p className="text-base md:text-base leading-snug text-black text-opacity-100 tracking-wide">
                 Sneak peek for 2D Isometric Pixel Version of CyberRide reveals. 
                </p>
              </div>
            </div>
            <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="order-1 w-5/12 px-1 py-4 text-right">
             
                <h4 className="mb-3 font-bold text-black text-lg md:text-2xl"> 100%</h4>
                <p className="text-base md:text-base leading-snug text-black text-opacity-100 tracking-wide">
                      Ride images disclose shortly after all rides sold out to ensure fairness. Cyberride Gallery goes live on CyberRide.io, showcasing model attributes and rarity. 
                
                 </p><br/>
                 <p className="text-base md:text-base leading-snug text-black text-opacity-100 tracking-wide">
                 OBJ download becomes available on CyberRide.io for ride owners.
                 </p>
              </div>
            </div>

           


            <div className="mb-8 flex justify-between items-center w-full right-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="order-1 w-5/12 px-1 py-4 text-left">
             <p className="mb-3 font-bold text-cyan-400 tracking-wide">After Gen-1</p>
                <h4 className="mb-3 font-bold text-black text-lg md:text-2xl tracking-wide"> Push for Mass Adoption</h4>
                <p className="text-base md:text-base leading-snug text-black text-opacity-100 tracking-wide">  
                  We will push the support for CyberRide models in major game/platforms, including ████████████, ███ ███████, and ██████.  <br/>
                </p>
                <br/>
                <p className="text-base md:text-base leading-snug text-black text-opacity-100 tracking-wide">
               By supporting games with millions of players, we can help push NFTs closer to mass adoption.
                </p>
                <br/>
               
              </div>
            </div>

             <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div className="order-1 w-5/12"></div>

              <div className="order-1  w-5/12 px-1 py-4">
                <p className="mb-3 font-bold text-cyan-400 tracking-wide">After Gen-1</p>
                <h4 className="mb-3 font-bold text-black text-lg md:text-2xl text-left tracking-wide">Interoperable API</h4>
                <p className="text-base md:text-base leading-snug text-black text-opacity-100 tracking-wide">
                Build a more user-friendly import process to use CyberRide across different platforms. The improved flow and API would apply to all digital items designed for the Metaverse.  </p> 
                   
              </div>
            </div>


           <div className="mb-8 flex justify-between items-center w-full right-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="order-1 w-5/12 px-1 py-4 text-left">
             
                <h4 className="mb-3 font-bold text-black text-lg md:text-2xl tracking-wide"> Gen-2</h4>
                <p className="text-base md:text-base leading-snug text-black text-opacity-100 tracking-wide">
                 ██,███ models of CyberRide Gen-2 are already in the works. Newly purchased CyberRide Gen-2 models have a ██% chance of collecting a CyberRide Gen-3 model for free.  
                </p><br/>
                <p className="text-base md:text-base leading-snug text-black text-opacity-100 tracking-wide">
                CyberRide models claimed for free do not unlock future generation CyberRide.  
                </p>


              </div>
            </div>

            <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div className="order-1 w-5/12"></div>

              <div className="order-1  w-5/12 px-1 py-4">
         
                <h4 className="mb-3 font-bold text-black text-lg md:text-2xl text-left tracking-wide">Gen-3 and Beyond</h4>
                <p className="text-base md:text-base leading-snug text-black text-opacity-100 tracking-wide">
                  Continue to develop new CyberRide models with the latest technology and design tools available. Improve interactivity, composability and interoperability so CyberRide can be seamlessly imported and used in any Metaverse. </p><br/>
 <p className="text-base md:text-base leading-snug text-black text-opacity-100 tracking-wide">
The CyberRide community will collectively decide the future direction of the project.
                </p><br/>
<p className="text-base md:text-base leading-snug text-black text-opacity-100 tracking-wide">
                Stay tuned. 
                 </p>

              </div>
            </div>


            



          </div>
          
        </div>
      </div>
    </div>
  </div>
  </section>
<div className="bg-gray-100">
  

      <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:pt-24 pb-10">
        <div className="space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-7xl">
          <h3 className="text-2xl text-left font-extrabold text-gray-900 tracking-normal lg:text-3xl">The Team:</h3>
       
           
          </div>
         <ul
            role="list"
            className="mx-auto space-y-12 sm:grid sm:grid-cols-3 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl"
          >

           <li key="emerge">
                <div className="space-y-6">
                  <img className="mx-auto h-20 w-20 rounded-full xl:w-32 xl:h-32" src="img/webp/emerge.webp" alt="" />
                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3 className="font-semibold text-gray-900" >Emerge</h3>
                      <p className="text-cyan-400 font-semibold">Founder, takes care of all things technical</p>
                    </div>
                    <ul role="list" className="flex justify-center space-x-5">
                      <li>
                        <a href="https://twitter.com/emerge_eth" className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Twitter</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>


               <li key="alex">
                <div className="space-y-6">
                  <img className="mx-auto h-20 w-20 rounded-full xl:w-32 xl:h-32" src="img/webp/alex.webp" alt="" />
                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3 className="font-semibold  text-gray-900">Alex Safayan</h3>
                      <p className="text-cyan-400 font-semibold">Voxel Artist</p>
                    </div>
                    <ul role="list" className="flex justify-center space-x-5">
                      <li>
                        <a href="https://twitter.com/alexsafayan" className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Twitter</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      </li>

                      <li>
                        <a href="https://www.instagram.com/newpxl/" className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Ins</span>
                          <svg className="w-5 h-5" fill="#8A939B" fill="currentColor" viewBox="0 0 24 24" ><path d="M15.75 2H8.25C4.79875 2 2 4.79875 2 8.25V15.75C2 19.2012 4.79875 22 8.25 22H15.75C19.2012 22 22 19.2012 22 15.75V8.25C22 4.79875 19.2012 2 15.75 2ZM20.125 15.75C20.125 18.1625 18.1625 20.125 15.75 20.125H8.25C5.8375 20.125 3.875 18.1625 3.875 15.75V8.25C3.875 5.8375 5.8375 3.875 8.25 3.875H15.75C18.1625 3.875 20.125 5.8375 20.125 8.25V15.75Z"></path><path d="M12 7C9.23875 7 7 9.23875 7 12C7 14.7613 9.23875 17 12 17C14.7613 17 17 14.7613 17 12C17 9.23875 14.7613 7 12 7ZM12 15.125C10.2775 15.125 8.875 13.7225 8.875 12C8.875 10.2763 10.2775 8.875 12 8.875C13.7225 8.875 15.125 10.2763 15.125 12C15.125 13.7225 13.7225 15.125 12 15.125Z"></path><path d="M17.375 7.29124C17.743 7.29124 18.0413 6.99295 18.0413 6.62499C18.0413 6.25703 17.743 5.95874 17.375 5.95874C17.0071 5.95874 16.7088 6.25703 16.7088 6.62499C16.7088 6.99295 17.0071 7.29124 17.375 7.29124Z"></path></svg>

                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li key="matt">
                <div className="space-y-6">
                  <img className="mx-auto h-20 w-20 rounded-full xl:w-32 xl:h-32" src="img/webp/matt.webp" alt="" />
                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3 className="font-semibold  text-gray-900">Matt I NFTs & Web3 </h3>
                      <p className="text-cyan-400 font-semibold">Collab Manager</p>
                    </div>
                    <ul role="list" className="flex justify-center space-x-5">
                      <li>
                        <a href="https://twitter.com/MattInWeb3" className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Twitter</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      </li>

                      
                    </ul>
                  </div>
                </div>
              </li>

        </ul>

        <ul
            role="list"
            className="mx-auto space-y-12 sm:grid sm:grid-cols-3 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl"
          >

              <li key="josh">
                <div className="space-y-6">
                  <img className="mx-auto h-20 w-20 rounded-full xl:w-32 xl:h-32" src="img/webp/josh.webp" alt="" />
                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3 className="font-semibold  text-gray-900">Jpeg Josh</h3>
                      <p className="text-cyan-400 font-semibold">Community Manager</p>
                    </div>
                    <ul role="list" className="flex justify-center space-x-5">
                      <li>
                        <a href="https://mobile.twitter.com/JPEGGINGTON" className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Twitter</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      </li>

                     
                    </ul>
                  </div>
                </div>
              </li>

             

              <li key="b3">
                <div className="space-y-6">
                  <img className="mx-auto h-20 w-20 rounded-full xl:w-32 xl:h-32" src="img/webp/brig3.webp" alt="" />
                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3 className="font-semibold  text-gray-900">Bridg3</h3>
                      <p className="text-cyan-400 font-semibold">Marketing and Advising</p>
                    </div>
                    <ul role="list" className="flex justify-center space-x-5">
                      <li>
                        <a href="https://mobile.twitter.com/bridge3_" className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Twitter</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      </li>

                     
                    </ul>
                  </div>
                </div>
              </li>







          </ul>
        </div>
      </div> 
  
   
  
   
 
</div>

<div className="bg-gray-100">
  <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
    <div className="md:flex md:items-center md:justify-between">
     
      <h3 className="text-2xl font-extrabold text-gray-900 tracking-normal lg:text-3xl">
          Our Aspirations:
          </h3>
    </div>
    
    <p className="mt-3 text-lg text-gray-500 tracking-wide">
      Apart from building cool rides that zoom across the Metaverse, there are a few aspirations that we also hope to achieve. 
    </p>
    <p className="mt-3 font-bold text-lg text-gray-800 tracking-wide">
For the past: </p>
   <p className="mt-3 text-lg text-gray-500 tracking-wide">To forever preserve on the blockchain the culture and artistic value of one human's most valuable and meaningful tools: our rides, even in the distant future when the vehicles we use today are no longer needed.
    </p>

    <p className="mt-3 text-lg font-bold text-gray-800 tracking-wide">For the future: </p>
    <p className="mt-3 text-lg text-gray-500 tracking-wide">To explore the possibility of the automobile, aerospace, watercraft, and spacecraft design when we remove all constraints of reality. In a virtual world where even the laws of physics can be ignored, the only limitation to how cool a ride is would be our imagination. </p>
    


    <div className="mt-8 text-sm md:hidden">
     
    </div>
  </div>
</div>

<div className="py-16 bg-white overflow-hidden lg:py-24" id='buy'>
  <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
    

    

    <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="relative">
        <h3 className="text-2xl font-extrabold text-gray-900 tracking-normal sm:text-3xl tracking-wide">
         Allowlist Minting Starts:
        </h3>
         <h3 className="text-2xl font-bold text-gray-800 tracking-normal sm:text-3xl tracking-wide">
        Feb 16, 2023 2pm PST / 5pm EST / 10pm UTC / 6am (Feb 17) China Standard Time
        </h3>
   

   <div className="mt-6">
              <a href="/allowlist" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-purple-500 to-cyan-500 bg-origin-border hover:bg-indigo-700">
               Allowlist Mint
              </a>
            </div>
           
        <dl className="mt-10 space-y-10">
          
         
        </dl>
      </div>

      <div className="mt-10 -mx-4 relative lg:mt-0  mr-0.5 ml-0.5" aria-hidden="true">
        {showCase3}
      
         

      

      </div>
    </div>

    
    
  </div>
</div>





</div>
</div>
</main>
  );

}



export default Home;
